import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import errorHandler from "../../core/store/errorHandler";
import BloqueioModal from "../../components/modals/alert/BloqueioModal";
import BotaoConfirmar from "../../components/buttons/btnConfirma";
import BotaoVoltar from "../../components/buttons/btnVoltar";
import AlteraModal from "../../components/modals/alert/AlteraModal";

import { StyleSheet, css } from "aphrodite";

import axios from "axios";
import { headers } from "../../core/store/localStorage";
import { Link, useMatch } from "react-router-dom";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import { Grid } from "@mui/material";

import {
  FormGroup,
  Input,
  Label,
  Pagination,
  PaginationLink,
  PaginationItem,
} from "reactstrap";

import Select from "react-select";

const AlteraBloqueio = (props) => {
  const { state } = useLocation();
  const location = useLocation();
  console.log(location);

  let infoDataIni = "";
  let infoDataFin = "";
  let blockId = "";
  let blockEmp = "";
  let blockEmpId = "";

  if (state) {
    infoDataIni = state.inicio;
    infoDataFin = state.fim;
    blockId = state.id;
    blockEmp = state.empresa;
    blockEmpId = state.empresaId;
  } else {
    infoDataIni = format(new Date(), "yyyy-MM-dd");
    infoDataFin = format(new Date(), "yyyy-MM-dd");
    blockId = "";
    blockEmp = "";
    blockEmpId = "";
  }

  const [dataInicial, setInicio] = useState(infoDataIni);
  const [dataFinal, setFim] = useState(infoDataFin);

  //   const [dataInicial, setInicio] = useState(state.inicio);
  //   const [dataFinal, setFim] = useState(state.fim);

  //   const [dataInicial, setInicio] = useState(format(new Date(), "yyyy-MM-dd"));
  //   const [dataFinal, setFim] = useState(format(new Date(), "yyyy-MM-dd"));

  const [erro, setErro] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const API_URL = localStorage.getItem("apiUrl");

  function atualizaInicio(ev) {
    setInicio(ev.target.value);
  }

  function atualizaFim(ev) {
    setFim(ev.target.value);
  }

  const retorna = () => {
    setModalOpen(!modalOpen);
  };

  function sendData(ev) {
    // ev.preventDefault();

    if (dataInicial > dataFinal) {
      setErro("Selecione uma data final posterior à data inicial.");
    } else {
      const [yearIni, monthIni, dayIni] = dataInicial.split("-");
      const formatDataIni = `${dayIni}/${monthIni}/${yearIni}`;

      const [yearFin, monthFin, dayFin] = dataFinal.split("-");
      const formatDataFin = `${dayFin}/${monthFin}/${yearFin}`;

      axios
        .put(
          `${API_URL}/periodobloqueado/${blockId}`,
          {
            tenantId: blockEmpId,
            inicio: formatDataIni,
            fim: formatDataFin,
          },
          headers()
        )
        .then((response) => {
          console.log(response);
        })
        .catch(errorHandler);
      if (errorHandler) {
        setErro("O período escolhido já está bloqueado. Selecione outra data.");
      } else {
        setModalOpen(true);
      }
    }
  }

  return (
    <>
      <h3 className={css(styles.pageTitle)}>Alterar Período</h3>
      <hr style={{ color: "#eee", marginTop: "-0.2rem" }} />

      <Grid
        container
        columns={{ md: 12 }}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        wrap="wrap"
      >
        <Grid item xs={12}>
          <div style={{ display: "flex" }}>
            <Grid item xs={1} style={{ marginRight: "2%" }}>
              <FormGroup>
                <Label className={css(styles.label)} for="empresa">
                  ID
                </Label>
                <Input
                  type="text"
                  value={blockId}
                  name="id"
                  id="id"
                  className={css(styles.fixedInput)}
                  disabled={true}
                ></Input>
              </FormGroup>
            </Grid>
            <Grid item xs={3} style={{ marginRight: "2%" }}>
              <FormGroup>
                <Label className={css(styles.label)} for="empresa">
                  Empresa
                </Label>
                <Input
                  type="text"
                  value={blockEmp}
                  name="empresa"
                  className={css(styles.fixedInput)}
                  disabled={true}
                ></Input>
              </FormGroup>
            </Grid>
            <FormGroup style={{ marginRight: "2%" }}>
              <Label for="inicio" className={css(styles.label)}>
                Início
              </Label>
              <Input
                type="date"
                name="inicio"
                id="inicio"
                placeholder="Data inicial"
                value={dataInicial}
                onChange={atualizaInicio}
                className={css(styles.input)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fim" className={css(styles.label)}>
                Fim
              </Label>
              <Input
                type="date"
                name="fim"
                id="fim"
                placeholder="Data final"
                value={dataFinal}
                onChange={atualizaFim}
                className={css(styles.input)}
              />
            </FormGroup>
          </div>
        </Grid>
      </Grid>

      <p className={css(styles.erro)}>{erro}</p>
      <AlteraModal modalOpen={modalOpen} toggle={retorna} />

      <div style={{ display: "flex" }}>
        <Link
          onClick={() => sendData()}
          style={{ marginRight: "0.5%", textDecoration: "none" }}
        >
          <BotaoConfirmar style={{ padding: 5 }} />
        </Link>
        <Link to={"/bloqueados"} style={{ textDecoration: "none" }}>
          <BotaoVoltar style={{ padding: 5 }} />
        </Link>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: "Poppins",
    color: "#2b2b2b",
    marginTop: "-0.15rem",
  },
  label: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left",
  },
  input: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    padding: 8,
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    width: "100%",
    borderRadius: 4,
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: "#CCCCCC",
  },
  fixedInput: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    padding: 8,
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    backgroundColor: "#E2E2E2",
    color: "#808080",
    width: "100%",
    borderRadius: 4,
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: "#CCCCCC",
  },
  select: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    width: "100%",
  },
  erro: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "red",
    textAlign: "left",
    fontWeight: 600,
  },
});

export default AlteraBloqueio;
