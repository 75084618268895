import { useState, useEffect } from "react";
import BotaoExcluir from "../../components/buttons/btnExclui";
import BotaoAlterar from "../../components/buttons/btnEdit";
import DeletaModal from "../../components/modals/alert/DeleteModal";
import SucessoModal from "../../components/modals/alert/SucessoModal";
import { Tooltip } from "react-tooltip";

import DatePicker from "react-datepicker";

import { StyleSheet, css } from "aphrodite";

import axios from "axios";
import { headers } from "../../core/store/localStorage";
import { Link, useMatch } from "react-router-dom";

import { format } from "date-fns";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

import { Grid } from "@mui/material";

import {
  FormGroup,
  Input,
  Label,
  Pagination,
  PaginationLink,
  PaginationItem,
} from "reactstrap";

import Select from "react-select";

import BotaoAdiciona from "../../components/buttons/btnAdd";
import "./../../assets/styles/tabela.css";
// import "./../../assets/styles/listarPagination.scss";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

// import "react-datepicker/dist/react-datepicker.css";
import "../../assets/styles/datePicker.css";
import "react-tooltip/dist/react-tooltip.css";
import moment from "moment";

registerLocale("pt-BR", ptBR);

const Bloqueados = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [sucessoOpen, setSucessoOpen] = useState(false);

  const [del, setDel] = useState(false);
  const [listaBlock, setListaBlock] = useState([]);
  const [empSelecionada, setEmpSelecionada] = useState("");
  const [empApi, setEmpApi] = useState([]);
  const [dataInicial, setInicio] = useState(new Date());
  const [dataFinal, setFim] = useState(new Date());

  const API_URL = localStorage.getItem("apiUrl");
  const idApi = `empresaId=${empSelecionada.id}&`;

  useEffect(() => {
    tabelaBloqueio();
    console.log(empApi);
    console.log(dataFinal);
  }, [empSelecionada, dataInicial, dataFinal]);

  useEffect(() => {
    listaEmpresas();
  }, [empSelecionada]);

  function tabelaBloqueio() {
    axios
      .get(
        `${API_URL}/periodobloqueado?${
          empSelecionada ? idApi : ""
        }dataInicial=${format(new Date(dataInicial), "dd/MM/yyyy", {
          locale: ptBR,
        })}&dataFinal=${format(new Date(dataFinal), "dd/MM/yyyy", {
          locale: ptBR,
        })}`,
        headers()
      )
      .then((response) => {
        let resp = [];
        response.data.map((res) => {
          resp.push({
            id: res.id,
            empresaId: res.empresaId,
            empresa: res.empresaNome,
            dataInicial: res.inicio,
            dataFinal: res.fim,
          });
        });
        setListaBlock(resp);
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function listaEmpresas() {
    axios
      .get(`${API_URL}/tenant/all`, headers())
      .then((response) => {
        var emps = [];
        response.data.map((emp) => {
          emps.push({
            id: `${emp.id}`,
            label: emp.name,
          });
        });
        emps.unshift({
          id: "",
          label: "Todos",
        });
        setEmpApi(emps);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleEmp = (ev) => {
    setEmpSelecionada(ev);
  };

  function atualizaInicio(date) {
    setInicio(date);
  }

  function atualizaFim(date) {
    setFim(date);
  }

  // function atualizaFim(ev) {
  //   setFim(ev.target.value);
  // }

  let qtdeBlock = listaBlock.length;
  if (qtdeBlock % 2 === 0) {
    qtdeBlock += 1;
  }

  const retorna = () => {
    setModalOpen(!modalOpen);
  };

  function startDelete(deleteId) {
    setModalOpen(true);
    setDel(deleteId);
  }

  const voltar = () => {
    setSucessoOpen(!sucessoOpen);
    window.location.reload();
  };

  function deleteblock() {
    axios
      .delete(`${API_URL}/periodobloqueado/${del}`, headers())
      .catch((err) => {
        console.log(err);
      });
    setModalOpen(!modalOpen);
    setSucessoOpen(!sucessoOpen);
  }

  function isSunday(date) {
    const day = date.getDay();
    return day !== 0;
  }

  return (
    <>
      <Grid container columns={{ md: 12 }} width={"100%"} wrap="wrap">
        <Grid item xs={11}>
          <h3 className={css(styles.pageTitle)}>Bloqueados</h3>
        </Grid>
        <Grid
          item
          xs={1}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <BotaoAdiciona />
        </Grid>
      </Grid>
      <hr style={{ color: "#eee", marginTop: "-0.2rem" }} />

      <Grid
        container
        columns={{ md: 12 }}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        wrap="wrap"
      >
        <Grid item xs={12}>
          <div style={{ display: "flex" }}>
            <Grid Grid item xs={4} style={{ marginRight: "2%" }}>
              <FormGroup>
                <Label className={css(styles.label)} for="atividade">
                  Empresa
                </Label>
                <Select
                  name="empresa"
                  placeholder="Empresa"
                  value={empSelecionada}
                  onChange={handleEmp}
                  options={empApi}
                  getOptionValue={(option) => option.id}
                  className={css(styles.select)}
                ></Select>
              </FormGroup>
            </Grid>
            <FormGroup style={{ marginRight: "2%" }}>
              <Label for="inicio" className={css(styles.label)}>
                Início
              </Label>
              {/* <Input
                type="date"
                name="inicio"
                id="inicio"
                placeholder="Data inicial"
                value={dataInicial}
                onChange={atualizaInicio}
                className={css(styles.input)}
              /> */}

              <DatePicker
                selected={dataInicial}
                onSelect={(date) => atualizaInicio(date)}
                onChange={atualizaInicio}
                locale="pt-BR"
                dateFormat={"dd/MM/yyyy"}
                filterDate={isSunday}
                className={css(styles.input)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fim" className={css(styles.label)}>
                Fim
              </Label>
              {/* <Input
                type="date"
                name="fim"
                id="fim"
                placeholder="Data final"
                value={dataFinal}
                onChange={atualizaFim}
                className={css(styles.input)}
              /> */}

              <DatePicker
                selected={dataFinal}
                onSelect={(date) => atualizaFim(date)}
                onChange={atualizaFim}
                locale="pt-BR"
                dateFormat={"dd/MM/yyyy"}
                filterDate={isSunday}
                className={css(styles.input)}
              />
            </FormGroup>
          </div>
        </Grid>
      </Grid>
      <Table>
        <Thead className="table_head">
          <Tr>
            <Th className="text-center">ID</Th>
            <Th className="text-center">Empresa</Th>
            <Th className="text-center">Data Inicial</Th>
            <Th className="text-center">Data Final</Th>
            <Th className="text-center">Ações</Th>
          </Tr>
        </Thead>
        <Tbody className="table_body">
          {listaBlock.map((list) => {
            var parity = qtdeBlock % 2;
            qtdeBlock = qtdeBlock - 1;

            let deleteId = list.id;

            const formatIni = list.dataInicial.replace(/[/]/g, "-");
            const [dayIni, monthIni, yearIni] = formatIni.split("-");
            const inicio = `${yearIni}-${monthIni}-${dayIni}`;

            const formatFin = list.dataFinal.replace(/[/]/g, "-");
            const [dayFin, monthFin, yearFin] = formatFin.split("-");
            const fim = `${yearFin}-${monthFin}-${dayFin}`;

            let exportData = {
              id: list.id,
              empresaId: list.empresaId,
              empresa: list.empresa,
              inicio: inicio,
              fim: fim,
            };
            console.log(list.dataInicial);

            return (
              <Tr
                key={`${list.id}`}
                className={parity === 1 ? "odd_row" : "even_row"}
              >
                <Td className="text-center">{list.id}</Td>
                <Td className="text-center">{list.empresa}</Td>
                <Td className="text-center">{list.dataInicial}</Td>
                <Td className="text-center">{list.dataFinal}</Td>
                <Td className="text-center" style={{ width: "10%" }}>
                  <span>
                    <Link onClick={() => startDelete(deleteId)}>
                      <BotaoExcluir />
                    </Link>
                    <Link
                      to={`/bloqueados-alterar/${list.id}`}
                      state={exportData}
                    >
                      <BotaoAlterar />
                    </Link>
                  </span>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <DeletaModal modalOpen={modalOpen} toggle={deleteblock} close={retorna} />
      <SucessoModal modalOpen={sucessoOpen} toggle={voltar} />
    </>
  );
};

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: "Poppins",
    color: "#2b2b2b",
    marginTop: "-0.15rem",
  },
  label: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left",
  },
  input: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    padding: 9,
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    width: "100%",
    borderRadius: 4,
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: "#CCCCCC",
  },
  select: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    width: "100%",
  },
});

export default Bloqueados;
