import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import "../../assets/styles/botoes.css";

const BotaoExcluir = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  //   const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <Button
        onClick={props.onClick}
        id="idApagar"
        className="btn-acoes btn-exclui btn-sm m-1"
        style={{ marginRight: "5%" }}
      >
        <DeleteIcon
          className="icon-acoes"
          style={{ width: "16px", textAlign: "center" }}
        />
      </Button>
      {/* <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target="idApagar"
        toggle={toggle}
      >
        Excluir
      </Tooltip> */}
    </>
  );
};

export default BotaoExcluir;
