import React, { useState, useEffect, useCallback } from "react";
import Calendar from "react-calendar";
import Time from "./Time";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import axios from "axios";
import { headers } from "../../core/store/localStorage";

import "./Calendario.css";

import { buttonBaseClasses, Grid } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { CommentsDisabledOutlined } from "@mui/icons-material";

const Agendamento = () => {
  const [date, setDate] = useState(new Date());
  const [month, setMonth] = useState("");
  const [horaApi, setHoraApi] = useState([]);
  const [showTime, setShowTime] = useState(false);
  const [marcado, setMarcado] = useState([]);

  const API_URL = localStorage.getItem("apiUrl");

  useEffect(() => {
    getHora();
  }, [date]);

  useEffect(() => {
    getAgenda();
    console.log(marcado);
  }, []);

  let endMonth = new Date(date.getFullYear(), date.getMonth() + 4, 0);

  function getAgenda() {
    axios
      .get(
        `${API_URL}/horarios/disponivel?dataInicial=${format(
          date,
          "dd/MM/yyyy",
          { locale: pt }
        )}&dataFinal=${format(endMonth, "dd/MM/yyyy", { locale: pt })}`,
        headers()
      )
      .then((response) => {
        if (response.data.length > 0) {
          var marks = [];
          response.data.map((mark) => {
            marks.push(mark.data);
          });
          setMarcado(marks);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //console.log(showTime);

  function open() {
    setShowTime(true);
  }
  function close() {
    setShowTime(false);
  }

  const getHora = () => {
    axios
      .get(
        `${API_URL}/horarios/disponivel?dataInicial=${format(
          date,
          "dd/MM/yyyy",
          { locale: pt }
        )}&dataFinal=${format(date, "dd/MM/yyyy", { locale: pt })}`,
        headers()
      )
      .then((response) => {
        //console.log(response);
        response.data.map((element) => {
          setHoraApi(element);
          // console.log(element);
        });
        // if (response.data.length <= 0) {
        if (response.data.length > 0) {
          open();
        } else {
          close();
          setHoraApi(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <h3 className={css(styles.pageTitle)}>Novo Agendamento</h3>
      <hr
        style={{ color: "#eee", marginBottom: "2rem", marginTop: "-0.5rem" }}
      />

      <Grid
        container
        columns={{ md: 12 }}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        wrap="wrap"
      >
        <Grid item xs={6}>
          <Calendar
            minDate={new Date()}
            tileDisabled={({ date }) => date.getDay() === 0}
            onChange={setDate}
            value={date}
            //onClickDay={getHora}
            tileClassName={({ date }) => {
              if (
                marcado.find(
                  (val) => val === format(date, "dd/MM/yyyy", { locale: pt })
                )
              ) {
                return "marked";
              }
            }}
          />
        </Grid>

        <Grid item xs={4} direction="column" alignItems="center">
          <Time
            showTime={showTime}
            // date={date}
            horaApi={horaApi}
          />
        </Grid>
      </Grid>
    </>
  );
};

const styles = StyleSheet.create({
  pageTitle: {
    color: "#2b2b2b",
    fontFamily: "Poppins",
    marginTop: "-0.15rem",
  },
});

export default Agendamento;
