import React from "react";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import uuid from "react-uuid";

import { Chart } from "react-google-charts";

import axios from "axios";
import { headers } from "../../core/store/localStorage";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import { Grid } from "@mui/material";

import {
  FormGroup,
  Input,
  Label,
  Pagination,
  PaginationLink,
  PaginationItem,
} from "reactstrap";

import Select from "react-select";

import "./../../assets/styles/tabela.css";
// import "./../../assets/styles/listarPagination.scss";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import { StyleSheet, css } from "aphrodite";
import "../../assets/styles/datePicker.css";

const Registros = () => {
  let key = uuid();

  const [listaUser, setListaUser] = useState([]);
  const [listaAtv, setListaAtv] = useState([]);

  const [dataInicial, setInicio] = useState(new Date());
  const [dataFinal, setFim] = useState(new Date());

  const [userSelecionado, setUserSelecionado] = useState("Todos");
  const [userApi, setUserApi] = useState([]);
  const [empSelecionada, setEmpSelecionada] = useState("Todas");
  const [empApi, setEmpApi] = useState([]);
  const [atvSelecionada, setAtvSelecionada] = useState("Todas");
  const [atvApi, setAtvApi] = useState([]);

  const [chartDataUser, setChartDataUser] = useState([]);
  const [chartDataAtv, setChartDataAtv] = useState([]);

  const [emptyArray, setEmptyArray, emptyArrayRef] = useState(false);

  const API_URL = localStorage.getItem("apiUrl");
  const getUsrApi = `usuario=${userSelecionado.label}&`;
  const getEmpApi = `empresaId=${empSelecionada.id}&`;
  const idApi = `atividade=${atvSelecionada.label}&`;

  useEffect(() => {
    tabelaUsuario();
    listaUsuarios();
    getChartUser();
    getChartAtv();
    console.log(userSelecionado);
  }, [dataInicial, dataFinal, userSelecionado, empSelecionada, atvSelecionada]);

  useEffect(() => {
    tabelaAtividade();
    listaAtividades();
    listaEmpresas();
  }, [dataInicial, dataFinal, userSelecionado, empSelecionada, atvSelecionada]);

  function tabelaUsuario() {
    axios
      .get(
        `${API_URL}/horarios/dashboard/user?${
          empSelecionada != "Todas" && empSelecionada.label != "Todos"
            ? getEmpApi
            : ""
        }${
          userSelecionado != "Todos" && userSelecionado.label != "Todos"
            ? getUsrApi
            : ""
        }dataInicial=${format(new Date(dataInicial), "dd/MM/yyyy", {
          locale: pt,
        })}&dataFinal=${format(new Date(dataFinal), "dd/MM/yyyy", {
          locale: pt,
        })}`,
        headers()
      )
      .then((response) => {
        let resp = [];
        response.data.map((res) => {
          resp.push({
            usuario: res.usuario,
            horas: res.horas,
            anoMes: res.anoMes,
          });
        });
        setListaUser(resp);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function tabelaAtividade() {
    axios
      .get(
        `${API_URL}/horarios/dashboard/atividade?${
          atvSelecionada != "Todas" && atvSelecionada.label != "Todas"
            ? idApi
            : ""
        }dataInicial=${format(new Date(dataInicial), "dd/MM/yyyy", {
          locale: pt,
        })}&dataFinal=${format(new Date(dataFinal), "dd/MM/yyyy", {
          locale: pt,
        })}`,
        headers()
      )
      .then((response) => {
        let resp = [];
        response.data.map((res) => {
          resp.push({
            empresa: res.empresa,
            atividade: res.atividade,
            horas: res.horas,
            anoMes: res.anoMes,
          });
        });
        setListaAtv(resp);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function listaUsuarios() {
    axios
      .get(`${API_URL}/user/all`, headers())
      .then((response) => {
        var usrs = [];
        response.data.map((usr) => {
          usrs.push({
            id: key,
            label: usr.name,
          });
        });
        usrs.unshift({
          id: "",
          label: "Todos",
        });
        setUserApi(usrs);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function listaEmpresas() {
    axios
      .get(`${API_URL}/tenant/all`, headers())
      .then((response) => {
        var emps = [];
        response.data.map((emp) => {
          emps.push({
            id: emp.id,
            label: emp.name,
          });
        });
        emps.unshift({
          id: "",
          label: "Todos",
        });
        console.log(emps[0].id);
        setEmpApi(emps);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function listaAtividades() {
    axios
      .get(`${API_URL}/atividade/all`, headers())
      .then((response) => {
        var atvs = [];
        response.data.map((atv) => {
          atvs.push({
            id: key,
            label: atv.nome,
          });
        });
        atvs.unshift({
          id: "",
          label: "Todas",
        });
        setAtvApi(atvs);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getChartUser() {
    axios
      .get(
        `${API_URL}/horarios/dashboard/grafico/user?${
          empSelecionada != "Todas" && empSelecionada.label != "Todos"
            ? getEmpApi
            : ""
        }${
          userSelecionado != "Todos" && userSelecionado.label != "Todos"
            ? getUsrApi
            : ""
        }dataInicial=${format(new Date(dataInicial), "dd/MM/yyyy", {
          locale: pt,
        })}&dataFinal=${format(new Date(dataFinal), "dd/MM/yyyy", {
          locale: pt,
        })}`,
        headers()
      )
      .then((response) => {
        if (typeof response.data !== "undefined" && response.data.length > 0) {
          let threeColumnChartData = [];
          let dataFormatada = "";
          let dtFormat = "";
          let horaFormatada = "";

          response.data.map((list) => {
            dataFormatada =
              list.anoMes.slice(4, 6) + "/" + list.anoMes.slice(0, 4);
          });

          const uniqueLabels = [
            "Período",
            ...new Set(response.data.map((item) => item.usuario)),
          ];

          const uniqueBars = [
            ...new Set(response.data.map((item) => item.anoMes)),
            // ...new Set(dataFormatada),
          ];

          threeColumnChartData.push(uniqueLabels);

          let arraysPorBarra = {};

          uniqueBars.forEach(function (bar) {
            let arrayBar = new Array(uniqueLabels.length);
            arrayBar.fill(0, 0, uniqueLabels.length);
            arrayBar[0] = bar;
            arraysPorBarra[bar] = arrayBar;
          });

          response.data.forEach(function (item) {
            dtFormat = item.anoMes.slice(4, 6) + "/" + item.anoMes.slice(0, 4);

            horaFormatada = parseInt(item.horas, 10);
            if (item.horas.slice(3, 4) === "3") {
              horaFormatada = horaFormatada + 0.5;
            }

            let indiceLabel = uniqueLabels.indexOf(item.usuario);
            arraysPorBarra[item.anoMes][indiceLabel] =
              JSON.parse(horaFormatada);
          });

          let arraysSoltos = Object.values(arraysPorBarra);

          arraysSoltos.map((array) => threeColumnChartData.push(array));
          setChartDataUser(threeColumnChartData);
        } else {
          setEmptyArray(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getChartAtv() {
    axios
      .get(
        `${API_URL}/horarios/dashboard/grafico/atividade?${
          empSelecionada != "Todas" && empSelecionada.label != "Todos"
            ? getEmpApi
            : ""
        }${
          userSelecionado != "Todos" && userSelecionado.label != "Todos"
            ? getUsrApi
            : ""
        }dataInicial=${format(new Date(dataInicial), "dd/MM/yyyy", {
          locale: pt,
        })}&dataFinal=${format(new Date(dataFinal), "dd/MM/yyyy", {
          locale: pt,
        })}`,
        headers()
      )
      .then((response) => {
        if (typeof response.data !== "undefined" && response.data.length > 0) {
          let threeColumnChartData = [];
          let dataFormatada = "";
          let dtFormat = "";
          let horaFormatada = "";

          response.data.map((list) => {
            dataFormatada =
              list.anoMes.slice(4, 6) + "/" + list.anoMes.slice(0, 4);
          });

          const uniqueLabels = [
            "Período",
            ...new Set(response.data.map((item) => item.atividade)),
          ];

          const uniqueBars = [
            ...new Set(response.data.map((item) => item.anoMes)),
            // ...new Set(dataFormatada),
          ];

          threeColumnChartData.push(uniqueLabels);

          let arraysPorBarra = {};

          uniqueBars.forEach(function (bar) {
            let arrayBar = new Array(uniqueLabels.length);
            arrayBar.fill(0, 0, uniqueLabels.length);
            arrayBar[0] = bar;
            arraysPorBarra[bar] = arrayBar;
          });

          response.data.forEach(function (item) {
            dtFormat = item.anoMes.slice(4, 6) + "/" + item.anoMes.slice(0, 4);

            horaFormatada = parseInt(item.horas, 10);
            if (item.horas.slice(3, 4) === "3") {
              horaFormatada = horaFormatada + 0.5;
            }

            let indiceLabel = uniqueLabels.indexOf(item.atividade);
            arraysPorBarra[item.anoMes][indiceLabel] =
              JSON.parse(horaFormatada);
          });

          let arraysSoltos = Object.values(arraysPorBarra);

          arraysSoltos.map((array) => threeColumnChartData.push(array));
          setChartDataAtv(threeColumnChartData);
        } else {
          setEmptyArray(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function atualizaInicio(date) {
    setInicio(date);
  }

  function atualizaFim(date) {
    setFim(date);
  }

  const handleUser = (ev) => {
    setUserSelecionado(ev);
  };

  const handleEmp = (ev) => {
    setEmpSelecionada(ev);
  };

  const handleAtv = (ev) => {
    setAtvSelecionada(ev);
  };

  let qtdeUser = listaUser.length;
  if (qtdeUser % 2 === 0) {
    qtdeUser += 1;
  }

  let qtdeAtv = listaAtv.length;
  if (qtdeAtv % 2 === 0) {
    qtdeAtv += 1;
  }

  //Opções dos gráficos//
  const options = {
    title: "Registro de Horas por Usuário",
    curveType: "function",
    chartArea: { width: "56%" },
    hAxis: {
      title: "Total de Horas",
      minValue: 0,
    },
    vAxis: {
      title: "Período",
    },
  };

  function isSunday(date) {
    const day = date.getDay();
    return day !== 0;
  }

  return (
    <>
      <h3 className={css(styles.pageTitle)}>Registros</h3>
      <hr style={{ color: "#eee", marginTop: "-0.2rem" }} />

      <Grid
        container
        columns={{ md: 12 }}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        wrap="wrap"
      >
        <Grid item xs={12}>
          <div style={{ display: "flex" }}>
            <Grid Grid item xs={2} style={{ marginRight: "2%" }}>
              <FormGroup>
                <Label className={css(styles.label)} for="usuário">
                  Usuário
                </Label>
                <Select
                  name="usuário"
                  placeholder="Usuários"
                  value={userSelecionado}
                  onChange={handleUser}
                  options={userApi}
                  getOptionValue={(option) => option.id}
                  className={css(styles.select)}
                ></Select>
              </FormGroup>
            </Grid>
            <Grid Grid item xs={2} style={{ marginRight: "2%" }}>
              <FormGroup>
                <Label className={css(styles.label)} for="empresa">
                  Empresa
                </Label>
                <Select
                  name="empresa"
                  placeholder="Empresa"
                  value={empSelecionada}
                  onChange={handleEmp}
                  options={empApi}
                  getOptionValue={(option) => option.id}
                  className={css(styles.select)}
                ></Select>
              </FormGroup>
            </Grid>
            <Grid Grid item xs={4} style={{ marginRight: "2%" }}>
              <FormGroup>
                <Label className={css(styles.label)} for="atividade">
                  Atividade
                </Label>
                <Select
                  name="atividade"
                  placeholder="Atividades"
                  value={atvSelecionada}
                  onChange={handleAtv}
                  options={atvApi}
                  getOptionValue={(option) => option.id}
                  className={css(styles.select)}
                ></Select>
              </FormGroup>
            </Grid>
            <Grid item xs={2} style={{ marginRight: "2%" }}>
              <FormGroup>
                <Label for="inicio" className={css(styles.label)}>
                  Início
                </Label>
                {/* <Input
                  type="date"
                  name="inicio"
                  id="inicio"
                  placeholder="Data inicial"
                  value={dataInicial}
                  onChange={atualizaInicio}
                  className={css(styles.input)}
                /> */}

                <DatePicker
                  selected={dataInicial}
                  onSelect={(date) => atualizaInicio(date)}
                  onChange={atualizaInicio}
                  locale="pt-BR"
                  dateFormat={"dd/MM/yyyy"}
                  filterDate={isSunday}
                  className={css(styles.input)}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={2}>
              <FormGroup>
                <Label for="fim" className={css(styles.label)}>
                  Fim
                </Label>
                {/* <Input
                  type="date"
                  name="fim"
                  id="fim"
                  placeholder="Data final"
                  value={dataFinal}
                  onChange={atualizaFim}
                  className={css(styles.input)}
                /> */}

                <DatePicker
                  selected={dataFinal}
                  onSelect={(date) => atualizaFim(date)}
                  onChange={atualizaFim}
                  locale="pt-BR"
                  dateFormat={"dd/MM/yyyy"}
                  filterDate={isSunday}
                  className={css(styles.input)}
                />
              </FormGroup>
            </Grid>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          justifyContent={"center"}
          style={{ marginTop: "5%" }}
        >
          <div style={{ display: "flex" }}>
            <Grid
              item
              xs={6}
              style={{ marginRight: "2%", justifyContent: "center" }}
            >
              <Chart
                chartType="Bar"
                data={chartDataUser}
                options={options}
                width="100%"
                height="300px"
                legendToggle
              />
            </Grid>
            <Grid item xs={6} style={{ justifyContent: "center" }}>
              <Chart
                chartType="Bar"
                data={chartDataAtv}
                options={options}
                width="100%"
                height="300px"
                legendToggle
              />
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div style={{ display: "flex" }}>
            <Grid item xs={6} style={{ marginRight: "1%" }}>
              <Table>
                <Thead className="table_head">
                  <Tr>
                    <Th className="text-center">Usuário</Th>
                    <Th className="text-center">Total de horas</Th>
                    <Th className="text-center">Mês/Ano</Th>
                  </Tr>
                </Thead>
                <Tbody className="table_body_regist">
                  {listaUser.map((list) => {
                    var parity = qtdeUser % 2;
                    qtdeUser = qtdeUser - 1;

                    let dataFormatada =
                      list.anoMes.slice(4, 6) + "/" + list.anoMes.slice(0, 4);

                    return (
                      <Tr
                        key={`${list.usuario}_${list.horas}_${dataFormatada}`}
                        className={parity === 1 ? "odd_row" : "even_row"}
                      >
                        <Td className="text-center">{list.usuario}</Td>
                        <Td className="text-center">{list.horas}</Td>
                        <Td className="text-center">{dataFormatada}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Grid>
            <Grid item xs={6}>
              <Table>
                <Thead className="table_head">
                  <Tr>
                    <Th className="text-center">Empresa</Th>
                    <Th className="text-center">Atividade</Th>
                    <Th className="text-center">Total de horas</Th>
                    <Th className="text-center">Mês/Ano</Th>
                  </Tr>
                </Thead>
                <Tbody className="table_body_regist">
                  {listaAtv.map((list) => {
                    var parity = qtdeAtv % 2;
                    qtdeAtv = qtdeAtv - 1;

                    let dataFormatada =
                      list.anoMes.slice(4, 6) + "/" + list.anoMes.slice(0, 4);

                    return (
                      <Tr
                        key={`${list.empresa}_${list.horas}_${dataFormatada}`}
                        className={parity === 1 ? "odd_row" : "even_row"}
                      >
                        <Td className="text-center">{list.empresa}</Td>
                        <Td className="text-center">{list.atividade}</Td>
                        <Td className="text-center">{list.horas}</Td>
                        <Td className="text-center">{dataFormatada}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: "Poppins",
    color: "#2b2b2b",
    marginTop: "-0.15rem",
  },
  label: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left",
  },
  line: {
    fontSize: 12,
  },
  input: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    padding: 8,
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    width: "100%",
    borderRadius: 4,
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: "#CCCCCC",
  },
  select: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    width: "100%",
  },
});

export default Registros;
