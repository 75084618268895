import { Box } from "@mui/material";

import iviagenda_iconmain from "../../assets/iviagenda_iconmain.png";

const MainPage = (): JSX.Element => {
  return (
    <>
      <Box
        width={"100%"}
        height={"60vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          component={"img"}
          src={iviagenda_iconmain}
          alt={"Logo IVIVenda"}
          width={{ xs: "90%", sm: "50%", md: "auto" }}
        />
      </Box>
    </>
  );
};

export default MainPage;
