import Order from '../../../models/order/order.model';
import OrderItem from '../../../models/order/orderItem.model';
import roundAbnt from '../../formaters/roundAbnt';

const itemRemovalCalculation = (order: Order, item: OrderItem) => {
  const newItems = order.itens.filter((old) => item.id !== old.id);
  order.itens = [...newItems];

  order.totais.produtos -= item.totais.produtos;

  const newTotal = newItems.reduce((acc, currValue) => {
    return acc + currValue.totais.total;
  }, 0);

  order.totais.total = roundAbnt(newTotal);
  order.totais.descontos.cabecalho.total = 0;
  order.totais.acrescimos.cabecalho.total = 0;

  if (item.totais.acrescimos.total && order.totais.acrescimos.itens.total) {
    order.totais.acrescimos.itens.total -= item.totais.acrescimos.total;
  }

  order.totais.acrescimos.total = order.totais.acrescimos.itens.total;

  if (item.totais.descontos.total && order.totais.descontos.itens.total) {
    order.totais.descontos.itens.total -= item.totais.descontos.total;
  }

  order.totais.descontos.total = order.totais.descontos.itens.total;
  order.totais.acrescimos.total = order.totais.acrescimos.itens.total;

  return order;
};

export default itemRemovalCalculation;
