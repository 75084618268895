// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from "firebase/remote-config";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBZHQqed7hLZ1ulin7HF8z3BGk6oiL5lgE",
  authDomain: "iviagenda-web.firebaseapp.com",
  projectId: "iviagenda-web",
  storageBucket: "iviagenda-web.appspot.com",
  messagingSenderId: "239763294021",
  appId: "1:239763294021:web:5f85a85c2ac0e537d6a02c",
  measurementId: "G-QRYBMY8TKJ",

  // apiKey: "AIzaSyDjZ8kW0CdH-x2Xr8vRgX5Kjzm7UlHE0mw",
  // authDomain: "ivivenda-web.firebaseapp.com",
  // projectId: "ivivenda-web",
  // storageBucket: "ivivenda-web.appspot.com",
  // messagingSenderId: "416807885560",
  // appId: "1:416807885560:web:b35fb07b1639bcaae0790d",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = {
  server_url_test: "aplicacao.ivitech.com.br/hivivenda",
};

export const activateFirebaseConfig = async (
  suffix: string
): Promise<string> => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      await fetchAndActivate(remoteConfig);

      if (suffix === "dev") {
        const value = getValue(remoteConfig, "server_url_dev");
        resolve(`https://${value.asString()}/api/v1`);
      } else if (suffix === "teste") {
        const value = getValue(remoteConfig, "server_url_test");
        resolve(`https://${value.asString()}/api/v1`);
      } else if (suffix === "local") {
        resolve("http://192.168.3.116:8080/diviagenda/api/v1");
      } else {
        const value = getValue(remoteConfig, "server_url_prod");
        resolve(`https://${value.asString()}/api/v1`);
      }
    } catch (err) {
      //console.log(err);
      reject({ message: "Network Error" });
    }
  });
};
