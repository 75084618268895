import { StyleSheet, css } from "aphrodite";
import { Dialog, Button, DialogTitle } from "@mui/material";

function CancelaModal(props) {
  let schdl = new Date(props.compare);

  return (
    <>
      {new Date() < schdl.setDate(schdl.getDate() - 2) ? (
        <Dialog
          open={props.modalOpen}
          onClose={props.close}
          PaperProps={{
            sx: {
              maxWidth: 650,
              maxHeight: 550,
              paddingRight: 3,
              paddingLeft: 3,
            },
          }}
        >
          <DialogTitle className={css(styles.title)}>
            Atividade agendada
          </DialogTitle>
          <div>
            <p className={css(styles.text)}>
              <b>Atividade:</b> {props.title}
            </p>
            <p className={css(styles.text)}>
              <b>Data agendada:</b> {props.schedule}
            </p>
            <hr style={{ color: "#eee" }} />

            {/* <p className={css(styles.text)}>
              <b>Descrição da atividade:</b>
            </p> */}
            <p className={css(styles.text)}>{props.description}</p>
            <hr style={{ color: "#eee" }} />

            <p className={css(styles.text)}>
              <b>Cancelar agendamento?</b>
            </p>
            <p className={css(styles.subtitle)}>
              Atividades só podem ser canceladas até dois dias antes da data
              agendada.
            </p>
          </div>
          <div className={css(styles.buttonContainer)}>
            <Button variant="outlined" color="error" onClick={props.toggle}>
              Cancelar
            </Button>
            <Button variant="outlined" color="info" onClick={props.close}>
              Voltar
            </Button>
          </div>
        </Dialog>
      ) : (
        <Dialog
          open={props.modalOpen}
          onClose={props.close}
          PaperProps={{
            sx: {
              maxWidth: 650,
              maxHeight: 550,
              paddingRight: 3,
              paddingLeft: 3,
            },
          }}
        >
          <DialogTitle className={css(styles.title)}>
            Atividade agendada
          </DialogTitle>
          <div>
            <p className={css(styles.text)}>
              <b>Atividade:</b> {props.title}
            </p>
            <p className={css(styles.text)}>
              <b>Data agendada:</b> {props.schedule}
            </p>
            <hr style={{ color: "#eee" }} />

            {/* <p className={css(styles.text)}>
              <b>Descrição da atividade:</b>
            </p> */}
            <p className={css(styles.text)}>{props.description}</p>
          </div>
          <div className={css(styles.buttonContainer)}>
            <Button variant="outlined" color="info" onClick={props.close}>
              Voltar
            </Button>
          </div>
        </Dialog>
      )}
    </>
  );
}

export default CancelaModal;

const styles = StyleSheet.create({
  title: {
    fontFamily: "Poppins",
    textAlign: "center",
    fontWeight: 700,
    marginBottom: -5,
  },
  text: {
    fontFamily: "Poppins",
    textAlign: "center",
  },
  subtitle: {
    color: "#F2474A",
    fontSize: 13,
    fontWeight: 500,
    fontFamily: "Poppins",
    textAlign: "center",
    marginTop: -10,
  },
  buttonContainer: {
    marginTop: "3%",
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "3%",
  },
});
