import React, { useState } from "react";
import Select from "react-select";
import { horas } from "./horas";
import { agenda } from "./agenda";
import { umaHora } from "./umaHora";
import { duasHoras } from "./duasHoras";

import SucessoModal from "../../components/modals/alert/SucessoModal";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import axios from "axios";
import { headers } from "../../core/store/localStorage";

import { FormGroup, Label, Input } from "reactstrap";
import { StyleSheet, css } from "aphrodite";
import { preventDefault } from "@fullcalendar/react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

function Horarios(props) {
  const [inicio, setInicio] = useState("");
  const [final, setFinal] = useState("");
  const [horaSelecionada, setHoraSelecionada] = useState("");
  const [intInicio, setIntInicio] = useState("");
  const [intFinal, setIntFinal] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [result, setResult] = useState("");
  const [timeForm, setTimeForm] = useState(false);
  const [timeValue, setTimeValue] = useState("");
  const [int, setInt] = useState(false);
  const [erro, setErro] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);

  const API_URL = localStorage.getItem("apiUrl");

  let dateSelected = props.date;
  console.log(dateSelected);
  // const dateFormat = format(dateSelected, "dd/MM/yyyy", { locale: pt });

  const time = [
    {
      value: 0,
      label: "Meia hora",
    },
    {
      value: 1,
      label: "Uma hora",
    },
    {
      value: 2,
      label: "Duas horas",
    },
  ];

  const handleTime = (ev) => {
    setTimeForm(true);
    setTimeValue(ev.value);
    setSelectedTime(ev);
  };

  const handleInicio = (ev) => {
    setInicio(ev);
  };

  const handleFinal = (ev) => {
    setFinal(ev);
  };

  const handleSelect = (ev) => {
    setHoraSelecionada(ev);
  };

  function handleClick(e) {
    preventDefault(e);

    // if (inicio.length === 0 || final.length === 0) {
    //   setErro("Selecione um horário de início e de término.");
    // } else if (inicio === final) {
    //   setErro("Selecione horários distintos de início e término.");
    // } else if (inicio.value > final.value) {
    //   setErro("Selecione um horário de término posterior ao de início.");
    if (horaSelecionada.length === 0) {
      setErro("Selecione um ou mais horários para serem registrados.");
    } else {
      setErro("");
      console.log(dateSelected);

      var send = [];
      dateSelected.forEach((dates) => {
        horaSelecionada.forEach((hr) => {
          send.push({
            data: dates,
            horaInicial: hr.horaInicial,
            horaFinal: hr.horaFinal,
            atividadeId: null,
            roleId: null,
            observacao: null,
          });
        });
      });

      console.log(send);

      // let lista = "";
      // let balance = 0;

      // if (selectedTime.label === "Meia hora") {
      //   lista = agenda;
      //   balance = 1;
      // } else if (selectedTime.label === "Uma hora") {
      //   lista = umaHora;
      //   balance = 1;
      // } else if (selectedTime.label === "Duas horas") {
      //   lista = duasHoras;
      //   balance = 1;
      // }

      // console.log(lista);

      // const printAgenda = lista.slice(
      //   inicio.value - balance,
      //   final.value - balance
      // );
      // setResult(printAgenda);
      // console.log(printAgenda);
      // // console.log(printIntervalo);

      // var send = [];
      // printAgenda.map((res) => {
      //   dateSelected.forEach((dates, index) => {
      //     send.push({
      //       data: dates,
      //       horaInicial: res.horaInicial,
      //       horaFinal: res.horaFinal,
      //       atividadeId: null,
      //       roleId: null,
      //       observacao: null,
      //     });
      //   });
      // });
      // console.log(send);

      axios
        .post(`${API_URL}/horarios/batch`, send, headers())
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
      setModalOpen(true);
    }
  }

  const retorna = () => {
    setModalOpen(!modalOpen);
    window.location.reload();
  };

  return (
    <div className={css(styles.selector)}>
      <Label className={css(styles.label)} for="periodo">
        Período de tempo
      </Label>
      <Select
        name="periodo"
        placeholder="Períodos"
        value={selectedTime}
        onChange={handleTime}
        options={time}
        className={css(styles.input)}
      ></Select>

      {timeForm ? (
        <form onSubmit={handleClick}>
          <FormGroup>
            <Label className={css(styles.label)} for="inicio">
              Selecione um ou mais horários
            </Label>
            <Select
              name="inicio"
              placeholder="Horários"
              value={horaSelecionada}
              onChange={handleSelect}
              options={
                timeValue === 0 ? agenda : timeValue === 1 ? umaHora : duasHoras
              }
              isMulti
              className={css(styles.input)}
            ></Select>

            {/* <Label className={css(styles.label)} for="final">
              Horário de término
            </Label>
            <Select
              name="final"
              className={css(styles.input)}
              placeholder="Horários"
              value={final}
              onChange={handleFinal}
              options={
                timeValue === 0 ? agenda : timeValue === 1 ? umaHora : duasHoras
              }
            ></Select> */}

            <input
              className={css(styles.btn)}
              type="submit"
              value="Registrar"
            />
            <p className={css(styles.erro)}>{erro}</p>
            <SucessoModal modalOpen={modalOpen} toggle={retorna} />
          </FormGroup>
        </form>
      ) : (
        ""
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    // justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  },
  selector: {
    justifyContent: "center",
    alignContent: "center",
    marginTop: "0.2rem",
  },
  label: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left",
  },
  input: {
    marginBottom: "1rem",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    width: "100%",
  },
  btn: {
    backgroundColor: "#fff",
    color: "#4b4b4b",
    border: "none",
    fontFamily: "Poppins",
    fontSize: 13,
    fontWeight: 600,
    borderRadius: 3,

    justifyContent: "center",
    alignContent: "center",
    padding: "0.5rem 2rem",
    margin: "0.3rem 0",
    width: "100%",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2), 0 2px 4px 0 rgba(0,0,0,0.19)",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#fff",
      backgroundColor: "#2CBA8D",
    },
    ":active": {
      color: "#fff",
      backgroundColor: "#097553",
    },
  },
  erro: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
  },
});

export default Horarios;
