import Times from "./Times.js";

function Time(props) {
  return (
    <div>
      {props.showTime ? (
        <Times date={props.date} horaApi={props.horaApi} />
      ) : (
        <p
          style={{
            fontFamily: "Poppins",
            textAlign: "center",
            marginTop: "3rem",
          }}
        >
          <b>Não há horários disponíveis nessa data. Selecione outro dia.</b>
        </p>
      )}
    </div>
  );
}

export default Time;
