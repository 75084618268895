import { Button } from "reactstrap";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import "../../assets/styles/botoes.css";

const BotaoVoltar = (props) => {
  return (
    <>
      <Button
        id="idVoltar"
        className="btn-sm botao-secundario"
        style={{ display: "flex", alignItems: "center" }}
      >
        <ArrowBackIcon />
        <span>Voltar</span>
      </Button>
    </>
  );
};

export default BotaoVoltar;
