import React, { useState, useEffect, useCallback } from "react";
import Calendar from "react-calendar";
import Horarios from "./Horarios";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import axios from "axios";
import { headers } from "../../core/store/localStorage";

import "./Calendario.css";

import { Grid } from "@mui/material";
import { StyleSheet, css } from "aphrodite";

const CadastraHorario = (props) => {
  const [date, setDate] = useState(new Date());
  const [listDays, setListDays] = useState([]);

  const API_URL = localStorage.getItem("apiUrl");

  const disabledDates = new Date() < date;

  useEffect(() => {
    addDay();
  }, [date]);

  const addDay = () => {
    let newDate = format(date, "dd/MM/yyyy", { locale: pt });
    // listDays.push(newDate);

    if (listDays.includes(newDate)) {
      setListDays(listDays.filter((value) => value !== newDate));
    } else {
      listDays.push(newDate);
    }
    console.log(newDate);
    console.log(listDays);
  };

  const changeTile = () => {};

  return (
    <>
      <h3 className={css(styles.pageTitle)}>Cadastro de Horário</h3>
      <hr
        style={{ color: "#eee", marginBottom: "2rem", marginTop: "-0.5rem" }}
      />

      <Grid
        container
        columns={{ md: 12 }}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        wrap="wrap"
      >
        <Grid item xs={6}>
          <Calendar
            minDate={new Date()}
            tileDisabled={({ date }) => date.getDay() === 0}
            onChange={setDate}
            value={date}
            tileClassName={({ date }) => {
              if (
                listDays.find(
                  (val) => val === format(date, "dd/MM/yyyy", { locale: pt })
                )
              ) {
                return "highlight";
              }
            }}
          />
        </Grid>
        <Grid item xs={4} direction="column" alignItems="center">
          <Horarios date={listDays} />
        </Grid>
      </Grid>
    </>
  );
};

const styles = StyleSheet.create({
  pageTitle: {
    color: "#2b2b2b",
    fontFamily: "Poppins",
    marginTop: "-0.15rem",
  },
});

export default CadastraHorario;
