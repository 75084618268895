import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Order from '../../models/order/order.model';
import OrderItem from '../../models/order/orderItem.model';
import NewPrices from '../../models/order/newPrices.model';
import OrderForm from '../../models/order/orderForm.model';
import OrderSituation from '../../models/order/orderSituation.model';
import SummarizedClient from '../../models/client/summarizedClient.model';
import PaymentConditions from '../../models/order/paymentConditions.model';
import ProductPriceTable from '../../models/product/productPriceTable.model';
import ORDER_FORM_INITIALIZER from '../../util/initializers/OrderFormInitializer';

import globalDiscountChange from '../../util/calculations/order/globalDiscountChange';
import globalIncreaseChange from '../../util/calculations/order/globalIncreaseChange';
import itemUpdateCalculation from '../../util/calculations/order/itemUpdateCalculation';
import itemAddtionCalculation from '../../util/calculations/order/itemAdditionCalculation';
import itemRemovalCalculation from '../../util/calculations/order/itemRemovalCalculation';
import tableChangeCalculations from '../../util/calculations/order/tableChangeCalculations';
import OrderPhase from '../../models/order/orderPhase.model';
import CustomItemDetail from '../../models/order/customItemDetail.model';

interface DateTime {
  data: string;
  hora: string;
}

const initialState: OrderForm = ORDER_FORM_INITIALIZER;

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    selectOrderClient(
      state: OrderForm,
      action: PayloadAction<SummarizedClient>
    ) {
      state.order.clienteId = action.payload.id;
      state.selectedClient = { ...action.payload };
    },
    setFullPriceTable(
      state: OrderForm,
      action: PayloadAction<ProductPriceTable>
    ) {
      state.selectedPriceTable = { ...action.payload };
    },
    setOrderPriceTable(state: OrderForm, action: PayloadAction<string>) {
      state.order.tabelaPrecoId = action.payload;
    },
    setOrderId(state: OrderForm, action: PayloadAction<string>) {
      state.order.id = action.payload;
    },
    setOrderTipoId(state: OrderForm, action: PayloadAction<string>) {
      state.order.tipoId = action.payload;
    },
    setOrderEmissao(
      state: OrderForm,
      action: PayloadAction<{ data: string; hora: string }>
    ) {
      state.order.emissao = { ...action.payload };
    },
    setPrevisao(
      state: OrderForm,
      action: PayloadAction<{ data: string; hora: string }>
    ) {
      state.order.previsao = { ...action.payload };
    },
    addOrderItem(state: OrderForm, action: PayloadAction<OrderItem>) {
      const order = { ...state.order };
      const newItem = { ...action.payload };

      const updatedOrder = itemAddtionCalculation(order, newItem);

      state.order = { ...updatedOrder };
    },
    setOrderVendedorIdOwner(state: OrderForm, action: PayloadAction<string>) {
      state.order.vendedorId = action.payload;
      state.order.owner = action.payload;
    },
    setObservation(state: OrderForm, action: PayloadAction<string>) {
      state.order.observacao = action.payload;
    },
    setItemToBeModified(state: OrderForm, action: PayloadAction<number>) {
      const item = state.order.itens.filter(
        (item) => item.id === action.payload
      );
      state.itemToBeModified = { ...item[0] };
    },
    resetItemToBeModified(state: OrderForm) {
      state.itemToBeModified = { ...initialState.itemToBeModified };
    },
    removeItem(state: OrderForm, action: PayloadAction<OrderItem>) {
      const order = { ...state.order };
      const item = { ...action.payload };

      const updatedOrder = itemRemovalCalculation(order, item);

      state.order = { ...updatedOrder };
    },
    editItem(state: OrderForm, action: PayloadAction<OrderItem>) {
      const order = { ...state.order };
      const item = { ...action.payload };

      const updatedOrder = itemUpdateCalculation(order, item);

      state.order = { ...updatedOrder };
    },
    handleTableChange(state: OrderForm, action: PayloadAction<NewPrices[]>) {
      const order = { ...state.order };
      const newPrices = [...action.payload];

      const updatedOrder = tableChangeCalculations(order, newPrices);

      state.order = { ...updatedOrder };
    },
    setOrderGlobalIncrease(
      state: OrderForm,
      action: PayloadAction<{ value: number; type: 'PERCENTAGE' | 'VALUE' }>
    ) {
      const order = { ...state.order };
      const increaseData = { ...action.payload };

      const updatedOrder = globalIncreaseChange(order, increaseData);

      state.order = { ...updatedOrder };
    },
    setOrderGlobalDiscount(
      state: OrderForm,
      action: PayloadAction<{ value: number; type: 'PERCENTAGE' | 'VALUE' }>
    ) {
      const order = { ...state.order };
      const discountData = { ...action.payload };

      const updatedOrder = globalDiscountChange(order, discountData);

      state.order = { ...updatedOrder };
    },
    setPaymentCondition(
      state: OrderForm,
      action: PayloadAction<PaymentConditions>
    ) {
      state.selectedPaymentCondition = { ...action.payload };
      state.order.condicaoPagamentoId = action.payload.id;
    },
    transmitOrder(
      state: OrderForm,
      action: PayloadAction<{ rascunho: boolean; transmissionDate: DateTime }>
    ) {
      if (action.payload.rascunho) {
        state.order.situacaoId = '0';
      } else {
        state.order.situacaoId = null;
        state.order.transmissao = { ...action.payload.transmissionDate };
      }
    },
    setOrderSituation(state: OrderForm, action: PayloadAction<OrderSituation>) {
      state.orderSituation = { ...action.payload };
    },
    setOrderPhase(state: OrderForm, action: PayloadAction<OrderPhase>) {
      state.orderPhase = { ...action.payload };
    },
    setOrder(state: OrderForm, action: PayloadAction<Order>) {
      state.order = { ...action.payload };
    },
    setItemsDetail(
      state: OrderForm,
      action: PayloadAction<CustomItemDetail[]>
    ) {
      state.itemsDetail = [...action.payload];
    },
    addItemDetail(state: OrderForm, action: PayloadAction<CustomItemDetail>) {
      state.itemsDetail.push(action.payload);
    },
    resetOrder() {
      return {
        ...initialState,
      };
    },
  },
});

export const orderActions = orderSlice.actions;

export default orderSlice;
