import React, { useState, useEffect } from "react";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import AgendamentoModal from "../../components/modals/alert/AgendamentoModal";

import { useAppSelector } from "../../store/hooks";

import axios from "axios";
import { headers } from "../../core/store/localStorage";

import { FormGroup, Label, Input } from "reactstrap";
import { StyleSheet, css } from "aphrodite";

import { makeStyles } from "@material-ui/core/styles";

function Times(props) {
  const classes = useStyles();

  const [atvSelecionada, setAtvSelecionada] = useState("");
  const [atvApi, setAtvApi] = useState([]);
  const [horaSelecionada, setHoraSelecionada] = useState("");
  const [horaApi, setHoraApi] = useState([]);
  const [txtValue, setTxtValue] = useState("");

  const [erro, setErro] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const API_URL = localStorage.getItem("apiUrl");
  const userId = localStorage.getItem("userId");

  let time = props.horaApi;

  useEffect(() => {
    setAtvSelecionada("");
    setHoraSelecionada("");
    listaAtividades();
    listaHora();
  }, [props.horaApi]);

  function listaAtividades() {
    axios
      .get(`${API_URL}/atividade`, headers())
      .then((response) => {
        var atvs = [];
        response.data.atividades.map((atv) => {
          atvs.push({
            value: `${atv.id}`,
            label: atv.nome,
          });
        });
        setAtvApi(atvs);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const listaHora = async () => {
    //console.log(time)
    var horas = [];
    var i = 1;
    time.horarios.map((hora) => {
      horas.push({
        value: ++i,
        label: hora.horaInicial + " - " + hora.horaFinal,
        inicio: hora.horaInicial,
        fim: hora.horaFinal,
      });
    });
    setHoraApi(horas);
    //console.log(horaApi);
  };

  const handleAtv = (ev) => {
    setAtvSelecionada(ev);
    //console.log(atvSelecionada);
  };

  const handleHora = (ev) => {
    setHoraSelecionada(ev);
    //console.log(ev);
  };

  const retorna = () => {
    setModalOpen(!modalOpen);
    window.location.reload();
  };

  const sendData = (e) => {
    e.preventDefault();

    if (
      atvSelecionada.length === 0 &&
      horaSelecionada.length === 0 &&
      txtValue.length === 0
    ) {
      setErro("Todos os campos devem ser preenchidos.");
    } else if (
      (atvSelecionada.length === 0 && horaSelecionada.length === 0) ||
      (atvSelecionada.length === 0 && txtValue.length === 0) ||
      (horaSelecionada.length === 0 && txtValue.length === 0)
    ) {
      setErro("Todos os campos devem ser preenchidos.");
    } else if (atvSelecionada.length === 0) {
      setErro("A atividade deve ser selecionada.");
    } else if (horaSelecionada.length === 0) {
      setErro("Um ou mais horários devem ser selecionados.");
    } else if (txtValue.length === 0) {
      setErro("Uma descrição da atividade deve ser preenchida.");
    } else {
      var results = [];
      horaSelecionada.map((res) => {
        results.push({
          horaInicial: res.inicio,
          horaFinal: res.fim,
        });
      });

      axios
        .post(`${API_URL}/horarios/agendar`, ({ data: time.data, atividadeId: atvSelecionada.value, 
          roleId: userId,
          observacao: txtValue, horarios: results }), headers())
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
      setModalOpen(true);
    }
  };

  const configTenant = useAppSelector((state) => state.tenantConfiguration);

  return (
    <div className="times">
      <div className={css(styles.selector)}>
        <form onSubmit={sendData}>
          <FormGroup>
            <Label className={css(styles.label)} for="atividade">
              Selecione uma atividade
            </Label>
            <Select
              name="atividade"
              placeholder="Atividades"
              value={atvSelecionada}
              onChange={handleAtv}
              options={atvApi}
              className={css(styles.input)}
            ></Select>

            <Label className={css(styles.label)} for="atividade">
              Selecione um ou mais horários
            </Label>
            <Select
              name="data_agendada"
              className={css(styles.input)}
              placeholder="Horários"
              value={horaSelecionada}
              onChange={handleHora}
              options={horaApi}
              noOptionsMessage={() => "Não há mais horários"}
              isMulti
              allowSelectAll={true}
              closeMenuOnSelect={true}
            ></Select>

            {!configTenant.ocultarDescricao ? (
              <div>
                <Label className={css(styles.label)} for="atividade">
                  Descrição da {configTenant.descricaoCampoAtividade}
                </Label>
                <TextField
                  className={classes.root}
                  id="outlined-multiline-flexible"
                  type="text"
                  multiline
                  maxRows={4}
                  inputProps={{
                    maxLength: 360,
                    style: {
                      fontSize: 12,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                    },
                  }}
                  value={txtValue}
                  onChange={(e) => setTxtValue(e.target.value)}
                />
              </div>
            ) : (
              ""
            )}

            <input className={css(styles.btn)} type="submit" value="Agendar" />
            <p className={css(styles.erro)}>{erro}</p>
            <AgendamentoModal modalOpen={modalOpen} toggle={retorna} />
          </FormGroup>
        </form>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    // justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  },
  selector: {
    justifyContent: "center",
    alignContent: "center",
    marginTop: "3rem",
  },
  label: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left",
  },
  input: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    width: "100%",
  },
  btn: {
    backgroundColor: "#fff",
    color: "#4b4b4b",
    border: "none",
    fontFamily: "Poppins",
    fontSize: 13,
    fontWeight: 600,
    borderRadius: 3,

    justifyContent: "center",
    alignContent: "center",
    padding: "0.5rem 2rem",
    margin: "0.3rem 0",
    width: "100%",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2), 0 2px 4px 0 rgba(0,0,0,0.19)",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#fff",
      backgroundColor: "#2CBA8D",
    },
    ":active": {
      color: "#fff",
      backgroundColor: "#097553",
    },
  },
  erro: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
  },
});

const useStyles = makeStyles({
  root: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    width: "100%",
    transitionDuration: "0.2s",

    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#AEAEAE",
      transitionDuration: "0.2s",
    },
  },
});

export default Times;
