import Order from '../../../models/order/order.model';
import OrderItem from '../../../models/order/orderItem.model';
import roundAbnt from '../../formaters/roundAbnt';

const itemAdditionCalculation = (order: Order, newItem: OrderItem) => {
  order.itens.push(newItem);
  order.totais.produtos += newItem.totais.produtos;

  if (order.totais.acrescimos.cabecalho.total) {
    if (order.totais.acrescimos.cabecalho.percentual) {
      const newHeaderIncrease = roundAbnt(
        (order.totais.produtos * order.totais.acrescimos.cabecalho.percentual) /
          100
      );
      order.totais.acrescimos.cabecalho.total = newHeaderIncrease;
    }
  }
  if (order.totais.acrescimos.itens.total) {
    if (newItem.totais.acrescimos.total) {
      order.totais.acrescimos.itens.total += newItem.totais.acrescimos.total;
    }
  } else {
    order.totais.acrescimos.itens.total = newItem.totais.acrescimos.total;
  }

  if (
    order.totais.acrescimos.cabecalho.total &&
    order.totais.acrescimos.itens.total
  ) {
    order.totais.acrescimos.total =
      order.totais.acrescimos.cabecalho.total +
      order.totais.acrescimos.itens.total;
  } else if (order.totais.acrescimos.cabecalho.total) {
    order.totais.acrescimos.total = order.totais.acrescimos.cabecalho.total;
  } else if (order.totais.acrescimos.itens.total) {
    order.totais.acrescimos.total = order.totais.acrescimos.itens.total;
  } else {
    order.totais.acrescimos.total = 0;
  }

  if (order.totais.descontos.cabecalho.total) {
    if (order.totais.descontos.cabecalho.percentual) {
      const newHeaderDiscount = roundAbnt(
        (order.totais.produtos * order.totais.descontos.cabecalho.percentual) /
          100
      );
      order.totais.descontos.cabecalho.total = newHeaderDiscount;
    }
  }

  if (order.totais.descontos.itens.total) {
    if (newItem.totais.descontos.total) {
      order.totais.descontos.itens.total += newItem.totais.descontos.total;
    }
  } else {
    order.totais.descontos.itens.total = newItem.totais.descontos.total;
  }

  if (
    order.totais.descontos.cabecalho.total &&
    order.totais.descontos.itens.total
  ) {
    order.totais.descontos.total =
      order.totais.descontos.cabecalho.total +
      order.totais.descontos.itens.total;
  } else if (order.totais.descontos.cabecalho.total) {
    order.totais.descontos.total = order.totais.descontos.cabecalho.total;
  } else if (order.totais.descontos.itens.total) {
    order.totais.descontos.total = order.totais.descontos.itens.total;
  } else {
    order.totais.descontos.total = 0;
  }

  if (order.totais.descontos.total && order.totais.acrescimos.total) {
    order.totais.total = order.totais.produtos;
    order.totais.total -= order.totais.descontos.total;
    order.totais.total += order.totais.acrescimos.total;
    order.totais.total = roundAbnt(order.totais.total);
  } else if (order.totais.descontos.total) {
    order.totais.total = order.totais.produtos;
    order.totais.total -= order.totais.descontos.total;
    order.totais.total = roundAbnt(order.totais.total);
  } else if (order.totais.acrescimos.total) {
    order.totais.total = order.totais.produtos;
    order.totais.total += order.totais.acrescimos.total;
    order.totais.total = roundAbnt(order.totais.total);
  } else {
    order.totais.total += newItem.totais.total;
    order.totais.total = roundAbnt(order.totais.total);
  }

  return order;
};

export default itemAdditionCalculation;
