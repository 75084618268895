import { useEffect, useLayoutEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useMatch,
} from "react-router-dom";

import { useAppDispatch, useAppSelector } from "./store/hooks";
import { clientActions } from "./store/client/client-slice";
import { clientFormErrorActions } from "./store/error/client-form-error-slice";
import MainLayout from "./components/layout/MainLayout";
import AuthContainer from "./components/authContainer/AuthContainer";
import LoginPage from "./pages/login/LoginPage";
import Agendamento from "./pages/agendamentos/Agendamento";
import QuadroAgendamentos from "./pages/quadro/QuadroAgendamentos";
import CadastraHorario from "./pages/configuracoes/CadastraHorario";
import MainPage from "./pages/main/MainPage";
import Registros from "./pages/registros/registros";
import Bloqueados from "./pages/bloqueados/bloqueados";
import BloqueiaPeriodo from "./pages/bloqueados/cadastraBloqueio";
import AlteraBloqueio from "./pages/bloqueados/alterarBloqueio";

import { citiesActions } from "./store/address/cities-slice";
import { orderActions } from "./store/order/order-slice";
import { readabilityActions } from "./store/readability/readability-slice";

function App() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const matchedClients = useMatch("/clientes");
  const matchedSales = useMatch("/vendas");
  const matchedSalesList = useMatch("/vendas/pesquisar");
  const API_URL = useAppSelector((state) => state.apiUrl.apiUrl);

  useEffect(() => {
    if (!!matchedClients) {
      dispatch(clientActions.resetClient());
      dispatch(clientFormErrorActions.resetErrors());
      dispatch(citiesActions.resetCities());
    }
  }, [location, matchedClients, dispatch]);

  useEffect(() => {
    if (!!matchedSales) {
      localStorage.removeItem("orderSearchParams");
    }
  }, [location, matchedSales]);

  useEffect(() => {
    if (!!matchedSales || !!matchedSalesList) {
      dispatch(orderActions.resetOrder());
      dispatch(readabilityActions.resetReadability());
    }
  }, [location, matchedSales, dispatch]);

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route element={<MainLayout />}>
        <Route
          path="/main"
          element={
            <AuthContainer>
              <MainPage />
            </AuthContainer>
          }
        />
        <Route
          path="/agendamentos"
          element={
            <AuthContainer>
              <Agendamento />
            </AuthContainer>
          }
        />
        <Route
          path="/agenda"
          element={
            <AuthContainer>
              <QuadroAgendamentos />
            </AuthContainer>
          }
        />
        <Route
          path="/registros"
          element={
            <AuthContainer>
              <Registros />
            </AuthContainer>
          }
        />
        <Route
          path="/bloqueados"
          element={
            <AuthContainer>
              <Bloqueados />
            </AuthContainer>
          }
        />
        <Route
          path="/bloqueados-adicionar"
          element={
            <AuthContainer>
              <BloqueiaPeriodo />
            </AuthContainer>
          }
        />
        <Route
          path="/bloqueados-alterar/:id"
          element={
            <AuthContainer>
              <AlteraBloqueio />
            </AuthContainer>
          }
        />

        <Route
          path="/cadastro"
          element={
            <AuthContainer>
              <CadastraHorario />
            </AuthContainer>
          }
        />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
