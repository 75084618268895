import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

import { StyleSheet, css } from "aphrodite";
import "../../assets/styles/botoes.css";

const BotaoAdiciona = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <Link to={"/bloqueados-adicionar"} className={css(styles.noDecoration)}>
        <Button id="idAdiciona" className="btn-sm mt-2 botao-add">
          <AddIcon />
        </Button>
        {/* <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target="idAdiciona"
          toggle={toggle}
        >
          Adicionar bloqueio
        </Tooltip> */}
      </Link>
    </>
  );
};

const styles = StyleSheet.create({
  noDecoration: {
    textDecoration: "none",
    color: "inherit",
  },
});

export default BotaoAdiciona;
