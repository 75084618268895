import React, { useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { StyleSheet, css } from "aphrodite";
import { Link, useMatch } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import { FiTarget } from "react-icons/fi";
import { IoAppsOutline, IoReceiptOutline } from "react-icons/io5";
import { MdEditNote } from "react-icons/md";

import logoIvitech_colorido from "../../assets/logoivitech_colorido.png";
import ivitech_reduced from "../../assets/ivitech_reduced.png";

import "../../assets/styles/drawer.css";

interface Props {
  open: boolean;
  openDialog: boolean;
  openQuerySubMenu: boolean;
  toggleDialog: () => void;
  toggleQueryMenu: () => void;
}

const DrawerItemsList = (props: Props) => {
  const { open, openDialog, openQuerySubMenu, toggleDialog, toggleQueryMenu } =
    props;

  const [openCadastroSubMenu, setOpenCadastroSubMenu] = useState(false);

  function toggleCadastroMenu() {
    setOpenCadastroSubMenu(!openCadastroSubMenu);
  }

  const master = localStorage.getItem("master");
  let checkUser = "";
  console.log(master);

  if (master === "true") {
    checkUser = "true";
  } else {
    checkUser = "false";
  }
  console.log(checkUser);

  return (
    <>
      <List style={{ marginTop: "-0.5rem" }}>
        <Link to={"/agendamentos"} className={css(styles.noDecoration)}>
          <ListItemButton
            selected={!!useMatch("/agendamentos/*")}
            key={"Agendamentos"}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <CalendarMonthOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Novo Agendamento"}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </Link>
        <Link to={"/agenda"} className={css(styles.noDecoration)}>
          <ListItemButton
            selected={!!useMatch("/agenda/*")}
            key={"Agenda"}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <EventAvailableIcon />
            </ListItemIcon>
            <ListItemText primary={"Agenda"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </Link>

        {checkUser === "true" ? (
          <Link to={"/cadastro"} className={css(styles.noDecoration)}>
            <ListItemButton
              // selected={!!useMatch("/cadastro/*")}
              key={"Cadastro"}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  // marginLeft: 1.5,
                }}
              >
                <MoreTimeOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Cadastro"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </Link>
        ) : (
          ""
        )}

        {checkUser === "true" ? (
          <Link to={"/registros"} className={css(styles.noDecoration)}>
            <ListItemButton
              // selected={!!useMatch("/registros/*")}
              key={"Registros"}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AssessmentOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Registros"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </Link>
        ) : (
          ""
        )}

        {checkUser === "true" ? (
          <Link to={"/bloqueados"} className={css(styles.noDecoration)}>
            <ListItemButton
              // selected={!!useMatch("/bloqueados/*")}
              key={"Bloqueados"}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <EventNoteOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Bloqueados"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </Link>
        ) : (
          ""
        )}

        {/* <ListItemButton
        onClick={toggleCadastroMenu}
        selected={!!useMatch("/cadastro/*")}
        key={"Cadastro"}
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          <SettingsOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={"Configurações"}
          sx={{ opacity: open ? 1 : 0 }}
        />
        {openCadastroSubMenu ? (
          <ExpandLess color="action" />
        ) : (
          <ExpandMore color="action" />
        )}
      </ListItemButton>
      {openCadastroSubMenu && <Divider />}
      <Collapse in={openCadastroSubMenu} timeout="auto" unmountOnExit>
        <Link to={"/cadastro"} className={css(styles.noDecoration)}>
          <ListItemButton
            selected={!!useMatch("/cadastro/*")}
            key={"Cadastro"}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                marginLeft: 1.5,
              }}
            >
              <MoreTimeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Cadastro"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </Link>
      </Collapse> */}

        <Divider />
        <ListItemButton
          key={"Sair"}
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={toggleDialog}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"Sair"} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </List>

      <div>
        <Link to={"https://ivitech.com.br"}>
          <img
            src={open ? logoIvitech_colorido : ivitech_reduced}
            style={{
              position: "absolute",
              bottom: 20,
              left: open ? "25%" : "15%",
              width: open ? "6.5rem" : "2.5rem",
            }}
          />
        </Link>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  noDecoration: {
    textDecoration: "none",
    color: "inherit",
  },
});

export default DrawerItemsList;
