export const agenda = [
  {
    value: 1,
    label: "09:00 - 09:30",
    horaInicial: "09:00",
    horaFinal: "09:30",
  },
  {
    value: 2,
    label: "09:30 - 10:00",
    horaInicial: "09:30",
    horaFinal: "10:00",
  },
  {
    value: 3,
    label: "10:00 - 10:30",
    horaInicial: "10:00",
    horaFinal: "10:30",
  },
  {
    value: 4,
    label: "10:30 - 11:00",
    horaInicial: "10:30",
    horaFinal: "11:00",
  },
  {
    value: 5,
    label: "11:00 - 11:30",
    horaInicial: "11:00",
    horaFinal: "11:30",
  },
  {
    value: 6,
    label: "11:30 - 12:00",
    horaInicial: "11:30",
    horaFinal: "12:00",
  },
  {
    value: 7,
    label: "12:00 - 12:30",
    horaInicial: "12:00",
    horaFinal: "12:30",
  },
  {
    value: 8,
    label: "12:30 - 13:00",
    horaInicial: "12:30",
    horaFinal: "13:00",
  },
  {
    value: 9,
    label: "13:00 - 13:30",
    horaInicial: "13:00",
    horaFinal: "13:30",
  },
  {
    value: 10,
    label: "13:30 - 14:00",
    horaInicial: "13:30",
    horaFinal: "14:00",
  },
  {
    value: 11,
    label: "14:00 - 14:30",
    horaInicial: "14:00",
    horaFinal: "14:30",
  },
  {
    value: 12,
    label: "14:30 - 15:00",
    horaInicial: "14:30",
    horaFinal: "15:00",
  },
  {
    value: 13,
    label: "15:00 - 15:30",
    horaInicial: "15:00",
    horaFinal: "15:30",
  },
  {
    value: 14,
    label: "15:30 - 16:00",
    horaInicial: "15:30",
    horaFinal: "16:00",
  },
  {
    value: 15,
    label: "16:00 - 16:30",
    horaInicial: "16:00",
    horaFinal: "16:30",
  },
  {
    value: 16,
    label: "16:30 - 17:00",
    horaInicial: "16:30",
    horaFinal: "17:00",
  },
  {
    value: 17,
    label: "17:00 - 17:30",
    horaInicial: "17:00",
    horaFinal: "17:30",
  },
  {
    value: 18,
    label: "17:30 - 18:00",
    horaInicial: "17:30",
    horaFinal: "18:00",
  },
];
