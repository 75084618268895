import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import Tooltip from "./tooltip";

import { format } from "date-fns";

import FullCalendar, {
  EventContentArg,
  preventDefault,
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

import axios from "axios";
import { headers } from "../../core/store/localStorage";

import CancelaModal from "../../components/modals/alert/CancelaModal";
import SucessoModal from "../../components/modals/alert/SucessoModal";

import "./Quadro.css";

const QuadroAgendamentos = () => {
  const [time, setTime] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [sucessoOpen, setSucessoOpen] = useState(false);

  const [updatedTitle, setUpdatedTitle] = useState("");
  const [updatedSchedule, setUpdatedSchedule] = useState("");

  const [updatedStart, setUpdatedStart] = useState("");
  const [updatedEnd, setUpdatedEnd] = useState("");

  const [comp, setComp] = useState("");
  const [swap, setSwap] = useState("");
  const [descript, setDescript] = useState("");

  const [mousePos, setMousePos] = useState({});
  const [tooltipShow, setTooltipShow] = useState(false);

  const API_URL = localStorage.getItem("apiUrl");

  useEffect(() => {
    axios
      .get(
        `${API_URL}/horarios/user${swap !== "" ? `?agendado=${swap}` : ""}`,
        headers()
      )
      .then((response) => {
        var resp = [];
        response.data.map((res) => {
          let data = res.data;
          const dataFormat = data.replace(/[/]/g, "-");
          const [day, month, year] = dataFormat.split("-");

          const today = format(new Date(), "yyyy-MM-dd");
          const formatDate = `${year}-${month}-${day}`;

          let tl = "";
          let color = "";

          if (formatDate < today && res.atividade === null) {
            tl = "Indisponível";
            color = "#e65050";
          } else if (res.atividade === null) {
            tl = "Disponível";
            color = "#2cba8d";
          } else {
            tl = res.atividade.nome;
            color = "#1087ff";
          }

          let addStart = "";
          let addEnd = "";

          if (res.horaInicial.length < 5) {
            addStart = "0" + res.horaInicial;
          } else {
            addStart = res.horaInicial;
          }

          if (res.horaFinal.length < 5) {
            addEnd = "0" + res.horaFinal;
          } else {
            addEnd = res.horaFinal;
          }

          resp.push({
            title: tl,
            start: `${year}-${month}-${day}` + "T" + addStart,
            end: `${year}-${month}-${day}` + "T" + addEnd,
            backgroundColor: color,
            extendedProps: {
              observacao: res.observacao,
            },
          });
        });
        setTime(resp);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [swap]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const handleClick = (modalTitle, modalSchedule, modalEnd, description) => {
    if (modalTitle === "Disponível" || modalTitle === "Indisponível") {
      setModalOpen(false);
    } else {
      setModalOpen(true);

      let addStart = "";
      let addEnd = "";
      let finalSchedule = "";

      const schedule = new Date(modalSchedule);
      const formatedSchedule =
        schedule.getDate() +
        "/" +
        (schedule.getMonth() + 1) +
        "/" +
        schedule.getFullYear();
      console.log(formatedSchedule);
      console.log(formatedSchedule.length);

      if (formatedSchedule.length <= 8) {
        finalSchedule =
          "0" +
          formatedSchedule.slice(0, 2) +
          "0" +
          formatedSchedule.slice(2, 8);
      } else if (
        formatedSchedule.length === 9 &&
        formatedSchedule.slice(2, 3) === "1"
      ) {
        finalSchedule = "0" + formatedSchedule;
      } else if (
        formatedSchedule.length === 9 &&
        (formatedSchedule.slice(0, 1) === "1" ||
          formatedSchedule.slice(0, 1) === "2" ||
          formatedSchedule.slice(0, 1) === "3")
      ) {
        finalSchedule =
          formatedSchedule.slice(0, 3) + "0" + formatedSchedule.slice(3, 9);
        console.log(finalSchedule);
      } else {
        finalSchedule = formatedSchedule;
      }
      console.log(finalSchedule);

      const startHours = schedule.getHours();
      if (startHours < 10) {
        JSON.stringify(startHours);
        addStart = "0" + startHours;
      } else {
        addStart = startHours;
      }
      const startMinutes = ("0" + schedule.getMinutes()).slice(-2);
      const start = addStart + ":" + startMinutes;

      const endFormat = new Date(modalEnd);
      const endHours = endFormat.getHours();
      if (endHours < 10) {
        JSON.stringify(endHours);
        addEnd = "0" + endHours;
      } else {
        addEnd = endHours;
      }
      const endMinutes = ("0" + endFormat.getMinutes()).slice(-2);
      const end = addEnd + ":" + endMinutes;

      setComp(schedule);
      setUpdatedTitle(modalTitle);
      setUpdatedSchedule(finalSchedule);
      setDescript(description);

      setUpdatedStart(start);
      setUpdatedEnd(end);

      console.log(start);
      console.log(end);
    }
  };

  const retorna = () => {
    setModalOpen(!modalOpen);
  };

  async function remove() {
    const results = {
      data: updatedSchedule,
      horaInicial: updatedStart,
      horaFinal: updatedEnd,
      atividadeId: null,
      roleId: null,
      observacao: null,
    };
    console.log(results);

    try {
      axios
        .post(`${API_URL}/horarios`, results, headers())
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log("Erro:", err);
    }
    setModalOpen(!modalOpen);
    setSucessoOpen(!sucessoOpen);
  }

  const voltar = () => {
    setSucessoOpen(!sucessoOpen);
    window.location.reload();
  };

  return (
    <>
      <h3 className={css(styles.pageTitle)}>Agenda</h3>
      <hr style={{ color: "#eee", marginTop: "-0.2rem" }} />

      <FullCalendar
        customButtons={{
          Todos: {
            text: "Todos",
            click: function () {
              setSwap("");
            },
          },
          Agendados: {
            text: "Agendados",
            click: function () {
              setSwap(1);
            },
          },
          Disponiveis: {
            text: "Disponíveis",
            click: function () {
              setSwap(0);
            },
          },
        }}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        initialView={"listMonth"}
        events={time}
        eventInteractive={true}
        noEventsText={"Não há atividades agendadas nesse mês."}
        headerToolbar={{
          left: "Todos,Agendados,Disponiveis",
          right: "title,prev,next,listMonth,dayGridMonth,today",
        }}
        locale={"pt-br"}
        buttonHints={{
          prev: "Mês anterior",
          next: "Mês seguinte",
          today: "Mês atual",
          listMonth: "Lista",
          dayGridMonth: "Calendário",
        }}
        buttonText={{
          today: "Data Atual",
          listMonth: "Lista",
          dayGridMonth: "Calendário",
        }}
        eventMouseEnter={function (info) {
          if (
            info.event.title !== "Disponível" &&
            info.event.title !== "Indisponível"
          ) {
            // alert("teste");
            setTooltipShow(true);
          }
          console.log(info.event.title);
        }}
        eventMouseLeave={function (info) {
          if (
            info.event.title !== "Disponível" &&
            info.event.title !== "Indisponível"
          ) {
            // alert("teste");
            setTooltipShow(false);
          }
        }}
        eventClick={function (info) {
          const modalTitle = info.event.title;
          const modalSchedule = info.event.startStr;
          const modalEnd = info.event.endStr;
          const description = info.event.extendedProps.observacao;
          handleClick(modalTitle, modalSchedule, modalEnd, description);
        }}
        eventDidMount={function (info) {
          // info.el.style.fontFamily = "Poppins";
          if (info.event.title === "Disponível") {
            // Change background color of row
            info.el.style.color = "#ADADAD";
          } else if (info.event.title === "Indisponível") {
            info.el.style.color = "#ADADAD";
          }
        }}
        height={"70vh"}
      />
      <CancelaModal
        modalOpen={modalOpen}
        title={updatedTitle}
        schedule={updatedSchedule}
        description={descript}
        compare={comp}
        toggle={remove}
        close={retorna}
      />
      <SucessoModal
        modalOpen={sucessoOpen}
        // title={updatedTitle}
        // schedule={updatedSchedule}
        toggle={voltar}
      />
      <Tooltip
        horizontal={mousePos.x}
        vertical={mousePos.y}
        show={tooltipShow}
      />
    </>
  );
};

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: "Poppins",
    color: "#2b2b2b",
    marginTop: "-0.15rem",
  },
  btn: {
    backgroundColor: "#fff",
    color: "#4b4b4b",
    border: "none",
    fontFamily: "Poppins",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 600,
    borderRadius: 3,

    justifyContent: "center",
    alignContent: "center",
    padding: "0.6rem 1.5rem",
    marginBottom: -500,
    width: "100%",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2), 0 2px 4px 0 rgba(0,0,0,0.19)",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#fff",
      backgroundColor: "#2CBA8D",
    },
    ":active": {
      color: "#fff",
      backgroundColor: "#097553",
    },
  },
});

export default QuadroAgendamentos;
