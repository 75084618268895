import { StyleSheet, css } from "aphrodite";
import { Dialog, Button, DialogTitle } from "@mui/material";
import { Link, useMatch } from "react-router-dom";

function BloqueioModal(props) {
  return (
    <Dialog
      open={props.modalOpen}
      onClose={props.toggle}
      PaperProps={{
        sx: {
          width: "30%",
          maxHeight: 300,
        },
      }}
    >
      <DialogTitle className={css(styles.title)}>
        Bloqueio concluído
      </DialogTitle>
      <div>
        <p className={css(styles.text)}>O período selecionado foi bloqueado.</p>
      </div>
      <div className={css(styles.buttonContainer)}>
        <Link to={"/bloqueados"} style={{ textDecoration: "none" }}>
          <Button variant="outlined" color="info">
            Voltar
          </Button>
        </Link>
      </div>
    </Dialog>
  );
}

export default BloqueioModal;

const styles = StyleSheet.create({
  title: {
    fontFamily: "Poppins",
    textAlign: "center",
    fontWeight: 700,
    marginBottom: -5,
  },
  text: {
    fontFamily: "Poppins",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "3%",
  },
});
