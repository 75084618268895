export const duasHoras = [
  {
    value: 1,
    label: "09:00 - 11:00",
    horaInicial: "09:00",
    horaFinal: "11:00",
  },
  {
    value: 2,
    label: "10:00 - 12:00",
    horaInicial: "10:00",
    horaFinal: "12:00",
  },
  {
    value: 3,
    label: "11:00 - 13:00",
    horaInicial: "11:00",
    horaFinal: "13:00",
  },
  {
    value: 4,
    label: "12:00 - 14:00",
    horaInicial: "12:00",
    horaFinal: "14:00",
  },
  {
    value: 5,
    label: "13:00 - 15:00",
    horaInicial: "13:00",
    horaFinal: "15:00",
  },
  {
    value: 6,
    label: "14:00 - 16:00",
    horaInicial: "14:00",
    horaFinal: "16:00",
  },
  {
    value: 7,
    label: "15:00 - 17:00",
    horaInicial: "15:00",
    horaFinal: "17:00",
  },
  {
    value: 8,
    label: "16:00 - 18:00",
    horaInicial: "16:00",
    horaFinal: "18:00",
  },
];
