import { useState, useEffect } from "react";
import BloqueioModal from "../../components/modals/alert/BloqueioModal";

import { StyleSheet, css } from "aphrodite";

import axios from "axios";
import { headers } from "../../core/store/localStorage";
import { Link, useMatch } from "react-router-dom";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import { Grid } from "@mui/material";

import {
  FormGroup,
  Input,
  Label,
  Pagination,
  PaginationLink,
  PaginationItem,
} from "reactstrap";

import Select from "react-select";
import BotaoVoltar from "./../../components/buttons/btnVoltar.js";
import BotaoConfirmar from "../../components/buttons/btnConfirma";

const BloqueiaPeriodo = () => {
  const [empSelecionada, setEmpSelecionada] = useState("");
  const [empApi, setEmpApi] = useState([]);
  const [dataInicial, setInicio] = useState(format(new Date(), "yyyy-MM-dd"));
  const [dataFinal, setFim] = useState(format(new Date(), "yyyy-MM-dd"));
  const [aguardando, setAguardando] = useState(false);

  const [erro, setErro] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const API_URL = localStorage.getItem("apiUrl");
  const API_ROOT = API_URL.replace("/web", "");

  useEffect(() => {
    listaEmpresas();
  }, [empSelecionada]);

  function listaEmpresas() {
    axios
      .get(`${API_URL}/tenant/all`, headers())
      .then((response) => {
        var emps = [];
        response.data.map((emp) => {
          emps.push({
            id: `${emp.id}`,
            label: emp.name,
          });
        });
        setEmpApi(emps);
        console.log(emps);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleEmp = (ev) => {
    setEmpSelecionada(ev);
  };

  function atualizaInicio(ev) {
    setInicio(ev.target.value);
  }

  function atualizaFim(ev) {
    setFim(ev.target.value);
  }

  function sendData(ev) {
    // ev.preventDefault();

    if (empSelecionada.length === 0) {
      setErro("Selecione uma empresa.");
    } else if (dataInicial > dataFinal) {
      setErro("Selecione uma data final posterior à data inicial.");
    } else {
      const [yearIni, monthIni, dayIni] = dataInicial.split("-");
      const formatDataIni = `${dayIni}/${monthIni}/${yearIni}`;

      const [yearFin, monthFin, dayFin] = dataFinal.split("-");
      const formatDataFin = `${dayFin}/${monthFin}/${yearFin}`;

      axios
        .post(
          `${API_URL}/periodobloqueado`,
          {
            tenantId: parseInt(empSelecionada.id),
            inicio: formatDataIni,
            fim: formatDataFin,
          },
          headers()
        )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
      setModalOpen(true);
    }
  }

  const [yearIni, monthIni, dayIni] = dataInicial.split("-");
  const formatDataIni = `${dayIni}/${monthIni}/${yearIni}`;

  const [yearFin, monthFin, dayFin] = dataFinal.split("-");
  const formatDataFin = `${dayFin}/${monthFin}/${yearFin}`;

  console.log(parseInt(empSelecionada.id));
  console.log(formatDataIni);
  console.log(formatDataFin);

  return (
    <>
      <h3 className={css(styles.pageTitle)}>Bloquear Período</h3>
      <hr style={{ color: "#eee", marginTop: "-0.2rem" }} />

      <Grid
        container
        columns={{ md: 12 }}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        wrap="wrap"
      >
        <Grid item xs={12}>
          <div style={{ display: "flex" }}>
            <Grid item xs={4} style={{ marginRight: "2%" }}>
              <FormGroup>
                <Label className={css(styles.label)} for="empresa">
                  Selecione uma empresa
                </Label>
                <Select
                  name="empresa"
                  placeholder="Empresa"
                  value={empSelecionada}
                  onChange={handleEmp}
                  options={empApi}
                  className={css(styles.select)}
                ></Select>
              </FormGroup>
            </Grid>
            <FormGroup style={{ marginRight: "2%" }}>
              <Label for="inicio" className={css(styles.label)}>
                Início
              </Label>
              <Input
                type="date"
                name="inicio"
                id="inicio"
                placeholder="Data inicial"
                value={dataInicial}
                onChange={atualizaInicio}
                className={css(styles.input)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fim" className={css(styles.label)}>
                Fim
              </Label>
              <Input
                type="date"
                name="fim"
                id="fim"
                placeholder="Data final"
                value={dataFinal}
                onChange={atualizaFim}
                className={css(styles.input)}
              />
            </FormGroup>
          </div>
        </Grid>
      </Grid>

      <p className={css(styles.erro)}>{erro}</p>
      <BloqueioModal modalOpen={modalOpen} />

      <div style={{ display: "flex" }}>
        <Link
          onClick={() => sendData()}
          // to={"/bloqueados"}
          style={{ marginRight: "0.5%", textDecoration: "none" }}
        >
          <BotaoConfirmar style={{ padding: 5 }} />
        </Link>
        <Link to={"/bloqueados"} style={{ textDecoration: "none" }}>
          <BotaoVoltar style={{ padding: 5 }} />
        </Link>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: "Poppins",
    color: "#2b2b2b",
    marginTop: "-0.15rem",
  },
  label: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left",
  },
  input: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    padding: 8,
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    width: "100%",
    borderRadius: 4,
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: "#CCCCCC",
  },
  select: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    width: "100%",
  },
  erro: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "red",
    textAlign: "left",
    fontWeight: 600,
  },
});

export default BloqueiaPeriodo;
