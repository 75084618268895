function Tooltip(props) {
  return (
    <>
      {props.show ? (
        <p
          style={{
            position: "absolute",
            left: props.horizontal + 10,
            top: props.vertical + 5,
            fontFamily: "Calibri",
            fontSize: 13,
            backgroundColor: "#fff",
            borderStyle: "solid",
            borderWidth: 0.5,
            borderColor: "#e0e0e0",
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 1.5,
            paddingBottom: 1.5,
          }}
        >
          Clique aqui para ver mais detalhes da atividade agendada
        </p>
      ) : (
        <p></p>
      )}
    </>
  );
}

export default Tooltip;
