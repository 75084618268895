import { StyleSheet, css } from "aphrodite";
import { Dialog, Button, DialogTitle } from "@mui/material";
import { Link } from "react-router-dom";

function AlteraModal(props) {
  return (
    <Dialog
      open={props.modalOpen}
      onClose={props.toggle}
      PaperProps={{
        sx: {
          width: "30%",
          maxHeight: 300,
        },
      }}
    >
      <DialogTitle className={css(styles.title)}>Período alterado</DialogTitle>
      <div className={css(styles.buttonContainer)}>
        <Link className={css(styles.btn)} to={"/bloqueados"}>
          <Button variant="outlined" color="info" onClick={props.toggle}>
            Voltar
          </Button>
        </Link>
      </div>
    </Dialog>
  );
}

export default AlteraModal;

const styles = StyleSheet.create({
  title: {
    fontFamily: "Poppins",
    textAlign: "center",
    fontWeight: 700,
    marginBottom: -5,
  },
  text: {
    fontFamily: "Poppins",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "3%",
  },
  btn: {
    textDecoration: "none",
  },
});
