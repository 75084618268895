import Order from '../../../models/order/order.model';
import NewPrices from '../../../models/order/newPrices.model';
import roundAbnt from '../../formaters/roundAbnt';

const tableChangeCalculations = (order: Order, newPrices: NewPrices[]) => {
  order.itens.forEach((item) => {
    let newPrice = newPrices.filter(
      (productPrice) => productPrice.id === item.produtoId
    );
    if (newPrice.length > 0 && newPrice[0].newPrice) {
      const newItemTotal = roundAbnt(
        newPrice[0].newPrice.preco * item.quantidade
      );

      item.unitario = newPrice[0].newPrice.preco;
      item.totais.acrescimos.total = 0;
      item.totais.acrescimos.item.percentual = 0;
      item.totais.acrescimos.item.valor = 0;
      item.totais.acrescimos.item.total = 0;
      item.totais.descontos.total = 0;
      item.totais.descontos.item.percentual = 0;
      item.totais.descontos.item.valor = 0;
      item.totais.descontos.item.total = 0;
      item.totais.total = newItemTotal;
      item.totais.produtos = newItemTotal;
    } else {
      const newItemTotal = roundAbnt(item.unitario * item.quantidade);

      item.totais.acrescimos.total = 0;
      item.totais.acrescimos.item.percentual = 0;
      item.totais.acrescimos.item.valor = 0;
      item.totais.acrescimos.item.total = 0;
      item.totais.descontos.total = 0;
      item.totais.descontos.item.percentual = 0;
      item.totais.descontos.item.valor = 0;
      item.totais.descontos.item.total = 0;
      item.totais.total = newItemTotal;
      item.totais.produtos = newItemTotal;
    }
    return item;
  });

  const newOrderTotal = order.itens.reduce((acc, currValue) => {
    return acc + currValue.totais.produtos;
  }, 0);

  order.totais.produtos = roundAbnt(newOrderTotal);
  order.totais.total = roundAbnt(newOrderTotal);
  order.totais.acrescimos.cabecalho.percentual = 0;
  order.totais.acrescimos.cabecalho.valor = 0;
  order.totais.acrescimos.cabecalho.total = 0;
  order.totais.acrescimos.itens.total = 0;
  order.totais.acrescimos.total = 0;
  order.totais.descontos.cabecalho.percentual = 0;
  order.totais.descontos.cabecalho.valor = 0;
  order.totais.descontos.cabecalho.total = 0;
  order.totais.descontos.itens.total = 0;
  order.totais.descontos.total = 0;

  return order;
};

export default tableChangeCalculations;
